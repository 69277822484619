const ultimateHealthBenefits = (
  ultimateHealthPolicyMax,
  ultimateHealthPolicy,
  ultimateHealthBrochure,
  ultimateHealthBrochureSimplifiedChinese
) => [
  {
    type: 'plans',
    columns: [
      {
        name: 'Ultimate Health',
        buttonText: 'Talk to an adviser',
        buttonUrl: '#adviser-contact',
      },
      {
        name: 'Ultimate Health Max',
        buttonText: 'Talk to an adviser',
        buttonUrl: '#adviser-contact',
      },
    ],
  },
  {
    type: 'header',
    columns: [
      {
        heading: 'Overall benefits (Benefit limits are per person, per year):',
      },
      {
        heading: '',
      },
      {
        heading: '',
      },
    ],
  },
  {
    type: 'benefits',
    items: [
      {
        type: 'benefit',
        category: 'Surgical related benefits',
        bothColumns: {
          summary: "<div>Up to:</div><div style='font-size: 28px;'>$600,000</div>",
          details:
            '<p><b>Hospital surgical benefit limit: $600,000</b></p><p>Maximum amount for surgery related benefits.</p>',
        },
      },
      {
        type: 'benefit',
        category: 'Non-surgical related benefits',
        bothColumns: {
          summary: "<div>Up to:</div><div style='font-size: 28px;'>$300,000</div>",
          details:
            '<p><b>Non-surgical benefit limit: $300,000</b></p><p>Maximum amount for non-surgical related benefits.</p>',
        },
      },
    ],
  },
  {
    type: 'header',
    columns: [
      {
        heading: 'Benefits (Benefit limits are per person, per year):',
      },
      {
        heading: '',
      },
      {
        heading: '',
      },
    ],
  },
  {
    type: 'benefits',
    items: [
      {
        type: 'benefit',
        category: 'Surgery',
        columns: [
          {
            summaryIcon: 'TickSystemIcon',
            details:
              "<p>Surgery in a recognised private hospital.</p><p>Includes costs for:</p><ul><li>Surgeon's operating <li>Anaesthetist's fees</li><li>Intensivist's fees</li><li>Hospital accommodation (e.g. the admitted patient's bed, a private room, excludes suites)</li><li>Operating theatre fees</li><li>Surgically implanted prosthesis (in line with the prosthesis schedule)</li><li>Laparoscopic disposable</li><li>In-hospital X-ray examination and ECG</li><li>Intensive post-operative care and special in-hospital nursing</li><li>In-hospital post-operative physiotherapy</li><li>Ancillary hospital charges (e.g. dressings, sutures, needles, bandages)</li><li>In-hospital pharmaceutical prescriptions that are funded by PHARMAC</li></ul>",
          },
          {
            summaryIcon: 'TickSystemIcon',
            details:
              "<p>Surgery in a recognised private hospital.</p><p>Includes costs for:</p><ul><li>Surgeon's operating <li>Anaesthetist's fees</li><li>Intensivist's fees</li><li>Hospital accommodation (e.g. the admitted patient's bed, a private room, excludes suites)</li><li>Operating theatre fees</li><li>Surgically implanted prosthesis (in line with the prosthesis schedule)</li><li>Laparoscopic disposable</li><li>In-hospital X-ray examination and ECG</li><li>Intensive post-operative care and special in-hospital nursing</li><li>In-hospital post-operative physiotherapy</li><li>Ancillary hospital charges (e.g. dressings, sutures, needles, bandages)</li><li>In-hospital pharmaceutical prescriptions, including non-PHARMAC medicines</li></ul>",
          },
        ],
      },
      {
        type: 'benefit',
        category: 'Oral surgery',
        bothColumns: {
          summaryIcon: 'TickSystemIcon',
          details:
            '<p>Dental surgery in a private hospital performed by a registered oral surgeon or maxillo-facial surgeon.</p>' +
            '<p>We will cover the removal of unerupted and impacted teeth (e.g. wisdom teeth) if a registered oral surgeon, dental practitioner or maxillo-facial surgeon performs the surgery. A 12-month waiting period applies.</p>',
        },
      },
      {
        type: 'benefit',
        category: 'Cancer treatments',
        columns: [
          {
            summaryIcon: 'TickSystemIcon',
            details:
              '<p>Covers costs for and relating to cancer treatment, including:</p><ul><li>Chemotherapy, radiotherapy, immunotherapy and brachytherapy</li><li>Hospital accommodation (e.g. the admitted patient’s bed, a private room, excludes suites)</li><li>In-hospital X-ray examination and ECG</li><li>Intensive post-operative care and special in-hospital nursing</li><li>In-hospital post-operative physiotherapy</li><li>Ancillary hospital charges (e.g. dressings, sutures, needles, bandages)</li><li>In-hospital pharmaceutical prescriptions that are funded by PHARMAC, and up to $20,000 non-PHARMAC chemotherapy or immunotherapy</li></ul>',
          },
          {
            summaryIcon: 'TickSystemIcon',
            details:
              '<p>Covers costs for and relating to cancer treatment, including:</p><ul><li>Chemotherapy, radiotherapy, immunotherapy and brachytherapy</li><li>Hospital accommodation (e.g. the admitted patient’s bed, a private room, excludes suites)</li><li>In-hospital X-ray examination and ECG</li><li>Intensive post-operative care and special in-hospital nursing</li><li>In-hospital post-operative physiotherapy</li><li>Ancillary hospital charges (e.g. dressings, sutures, needles, bandages)</li><li>In-hospital pharmaceutical prescriptions, including non-PHARMAC medicines</li></ul>',
          },
        ],
      },
      {
        type: 'benefit',
        category: 'Follow-up investigations for cancer',
        bothColumns: {
          summaryIcon: 'TickSystemIcon',
          details:
            '<p>Specialist consultation and diagnostic investigation following cancer treatment.</p><p>We cover one specialist consultation, and relevant diagnostic investigations each policy year. We pay up to $3,000 each year for up to five consecutive years.</p>',
        },
      },
      {
        type: 'benefit',
        category: 'ACC top up',
        bothColumns: {
          summaryIcon: 'TickSystemIcon',
          details:
            '<p>Covers the difference in cost payable by ACC for a physical injury and the actual costs of surgery or medical treatment.</p>',
        },
      },
      {
        type: 'benefit',
        category: 'Hospital specialist consultations',
        bothColumns: {
          summaryIcon: 'TickSystemIcon',
          details:
            '<p>Covers specialist consultations including visits to seek a second opinion up to six months before and six months after admission to a private hospital.</p><p>Unlimited consultations during this time, up to your Surgical or Non-Surgical Benefit limit.</p>',
        },
      },
      {
        type: 'benefit',
        category: 'Hospital diagnostic tests',
        bothColumns: {
          summaryIcon: 'TickSystemIcon',
          details:
            '<p>Diagnostic investigations up to six months before and six months after admission to a private hospital.</p><p>Unlimited diagnostic investigations during this time, up to your Surgical or Non-Surgical Benefit limit.</p>',
        },
      },
      {
        type: 'benefit',
        category: 'Post-hospital physiotherapy',
        bothColumns: {
          summaryIcon: 'TickSystemIcon',
          details:
            '<p>Physiotherapy up to six months after hospitalisation.</p><p>Unlimited consultations during this time, up to your Surgical or Non-Surgical Benefit limit.</p>',
        },
      },
      {
        type: 'benefit',
        category: 'Post-hospital home care',
        bothColumns: {
          summaryIcon: 'TickSystemIcon',
          details:
            '<p>Home care up to six months after hospitalisation.</p>' +
            '<p>We pay up to $300 each day and $6,000 each policy year.</p>',
        },
      },
      {
        type: 'benefit',
        category: 'Travel and accommodation',
        bothColumns: {
          summaryIcon: 'TickSystemIcon',
          details:
            '<p>Travel costs for you and a support person, and accommodation costs for a support person when you need to travel further than 100km from where you live for treatment and are an admitted patient in a private hospital.</p>' +
            '<p>Surgery and medical treatment: we pay up to $3,000 for travel and for accommodation, up to the balance available in the Surgical or Non-Surgical benefit limit, each policy year.</p>' +
            '<p>Cancer treatment: we pay up to the balance available in the Surgical or Non-Surgical benefit limit for both travel and accommodation each policy year.</p>',
        },
      },
      {
        type: 'benefit',
        category: 'Ambulance transfer',
        bothColumns: {
          summaryIcon: 'TickSystemIcon',
          details:
            '<p>Road ambulance from a public or private hospital to the closest private hospital.</p>',
        },
      },
      {
        type: 'benefit',
        category: 'Suspension of cover',
        bothColumns: {
          summaryIcon: 'TickSystemIcon',
          details:
            '<p>After 12 months of continuous cover, you can put your cover on hold for up to six months due to unemployment or redundancy, for up to 12 months for parental leave, and up to 24 months for travel or residence outside of New Zealand.</p>',
        },
      },
      {
        type: 'benefit',
        category: 'Waiver of premium',
        bothColumns: {
          summaryIcon: 'TickSystemIcon',
          details:
            '<p>We cover the cost of all premiums on the policy for up to two years if a policyowner dies before age 70.</p>',
        },
      },
      {
        type: 'benefit',
        category: 'Funeral support benefit',
        columns: [
          {
            summaryIcon: 'TickSystemIcon',
            details:
              '<p>We pay a contribution towards funeral costs if an insured person dies between the age of 16 and 64 (inclusive).</p><p>We pay $5,000.</p>',
          },
          {
            summaryIcon: 'TickSystemIcon',
            details:
              '<p><b>TIP:</b> This benefit has a higher limit on Ultimate Health Max than Ultimate Health, see below.</p><p>We pay a contribution towards funeral costs if an insured person dies between the age of 16 and 64 (inclusive).</p><p>We pay $10,000.</p>',
          },
        ],
      },
      {
        type: 'benefit',
        category: 'GP surgery',
        columns: [
          {
            summaryIcon: 'TickSystemIcon',
            details:
              '<p>Minor surgery performed by a GP. </p><p>We pay up to $1500 each policy year.</p>',
          },
          {
            summaryIcon: 'TickSystemIcon',
            details:
              '<p><b>TIP:</b> This benefit has a higher limit on Ultimate Health Max than Ultimate Health, see below.</p><p>Minor surgery performed by a GP. </p><p>We pay up to $5000 each policy year.</p>',
          },
        ],
      },
      {
        type: 'benefit',
        category: 'Specialist skin lesion surgery',
        columns: [
          {
            summaryIcon: 'TickSystemIcon',
            details:
              '<p>Skin lesion surgery performed by a specialist.</p><p>Unlimited, up to your Surgical Benefit Limit.</p>',
          },
          {
            summaryIcon: 'TickSystemIcon',
            details:
              '<p><b>TIP:</b> This benefit has a higher limit on Ultimate Health Max than Ultimate Health, see below.</p><p>Skin lesion surgery performed by a specialist.</p><p>Unlimited up to the hospital surgical maximum each policy year.</p>',
          },
        ],
      },
      {
        type: 'benefit',
        category: 'Varicose veins surgery',
        bothColumns: {
          summaryIcon: 'TickSystemIcon',
          details:
            '<p>Varicose vein surgery performed by an appropriate specialist or a Phlebologist who is a Fellow of the Australasian College of Phlebology, in private practice, and holds a current practising certificate.</p><p>Unlimited, up to your Surgical Benefit Limit.</p>',
        },
      },
      {
        type: 'benefit',
        category: 'Non-surgical hospitalisation',
        columns: [
          {
            summaryIcon: 'TickSystemIcon',
            details:
              '<p>Covers medical treatment (not involving surgery) in a private hospital in relation to a medical condition (e.g. heart disease, asthma, pneumonia or diabetes).</p><p>We also cover the cost of associated intensive nursing care, X-rays, disposables and consumables, dressings and in-hospital pharmaceutical prescriptions that are funded by PHARMAC.</p><p>In addition, this benefit covers less invasive procedures as an alternative to surgery.</p>',
          },
          {
            summaryIcon: 'TickSystemIcon',
            details:
              '<p>Covers medical treatment (not involving surgery) in a private hospital in relation to a medical condition (e.g. heart disease, asthma, pneumonia or diabetes).</p><p>We also cover the cost of associated intensive nursing care, X-rays, disposables and consumables, dressings and in-hospital pharmaceutical prescriptions, including non-PHARMAC medicines.</p><p>In addition, this benefit covers less invasive procedures as an alternative to surgery.</p>',
          },
        ],
      },
      {
        type: 'benefit',
        category: 'Public hospital payment',
        bothColumns: {
          summaryIcon: 'TickSystemIcon',
          details:
            '<p>Provides a payment when you are admitted to a public hospital in New Zealand for three or more consecutive nights.</p><p>We pay up to $300 each night for the third and each subsequent night and up to $3,000 each policy year.</p>',
        },
      },
      {
        type: 'benefit',
        category: 'Major diagnostics (not related to hospitalisation)',
        bothColumns: {
          summaryIcon: 'TickSystemIcon',
          details:
            '<p>Covers for the following diagnostic investigations, whether or not you have been hospitalised:</p>' +
            '<ul><li>Arthroscopy</li>' +
            '<li>Capsule endoscopy</li>' +
            '<li>Colonoscopy</li>' +
            '<li>Colposcopy</li>' +
            '<li>CT Scan</li>' +
            '<li>CT Angiogram</li>' +
            '<li>Cystoscopy</li>' +
            '<li>Gastroscopy</li>' +
            '<li>MRI Scan</li>' +
            '<li>Myelogram</li>' +
            '<li>PET Scan</li></ul>' +
            '<p>No limit per diagnostic investigation. Unlimited diagnostic investigations each year, up to your Surgical or Non-Surgical Benefit Limit.</p>',
        },
      },
      {
        type: 'benefit',
        category: 'Parent accommodation',
        bothColumns: {
          summaryIcon: 'TickSystemIcon',
          details:
            '<p>Accommodation costs for a parent or legal guardian accompanying an insured person under age 20 (inclusive) who is being treated in a private hospital.</p><p>We pay up to $300 each night, and up to $3,000 each policy year.</p>',
        },
      },
      {
        type: 'benefit',
        category: 'Post-hospital therapeutic care',
        bothColumns: {
          summaryIcon: 'TickSystemIcon',
          details:
            '<p>Cover for the cost of the following up to six months after being discharged from a private hospital:</p><ul> <li>Osteopathic treatment</li> <li>Chiropractic treatment</li>  <li>Sports Physician treatment</li> <li>Speech Therapy</li><li>Occupational Therapy</li><li>Dietitian consultations</li></ul>',
        },
      },
      {
        type: 'benefit',
        category: 'Overseas treatment',
        bothColumns: {
          summaryIcon: 'TickSystemIcon',
          details:
            '<p>Covers treatment and travel costs when treatment cannot be provided at all in New Zealand and the Ministry of Health has declined funding.</p><p>We cover up to $30,000 for each overseas treatment.</p>',
        },
      },
      {
        type: 'benefit',
        category: 'Cover in Australia',
        columns: [
          {
            summaryIcon: 'TickSystemIcon',
            details:
              '<p>Covers cost for treatment undertaken in Australia.</p><p>We pay up to the Efficient Market Price that would be payable in New Zealand for treatment performed in New Zealand.</p>',
          },
          {
            summaryIcon: 'TickSystemIcon',
            details:
              '<p>Covers cost for treatment undertaken in Australia.</p><p>We pay up to the Usual, Customary and Reasonable Charges that would be payable in New Zealand for treatment performed in New Zealand.</p>',
          },
        ],
      },
      {
        type: 'benefit',
        category: 'Eye injections',
        columns: [
          {
            summaryIcon: 'TickSystemIcon',
            details:
              '<p>Intravitreal eye injections administered by a specialist and the cost of the PHARMAC-funded medicine.</p><p>We pay up to $3,000 each policy year.</p>',
          },
          {
            summaryIcon: 'TickSystemIcon',
            details:
              '<p>Intravitreal eye injections administered by a specialist and the cost of the medicine, including non-PHARMAC medicines.</p><p>We pay up to $3,000 each policy year.</p>',
          },
        ],
      },
      {
        type: 'benefit',
        category: 'High-Risk pregnancy',
        columns: [
          {
            summaryIcon: 'TickSystemIcon',
            details:
              '<p>Treatment by an obstetrician for assessment and monitoring of recognised risk factors.</p><p>We pay up to $2,000 each pregnancy.</p>',
          },
          {
            summaryIcon: 'TickSystemIcon',
            details:
              '<p><b>TIP:</b> This benefit has a higher limit on Ultimate Health Max than Ultimate Health, see below.</p><p>Treatment by an obstetrician for assessment and monitoring of recognised risk factors.</p><p>We pay up to $4,000 each pregnancy.</p>',
          },
        ],
      },
      {
        type: 'benefit',
        category: 'Loyalty benefit – sterilisation',
        columns: [
          {
            summaryIcon: 'TickSystemIcon',
            details:
              '<p>After two years continuous cover, we cover the cost of a male or female sterilisation as a means of contraception.</p><p>We pay up to $1000.</p>',
          },
          {
            summaryIcon: 'TickSystemIcon',
            details:
              '<p><b>TIP:</b> This benefit has a higher limit on Ultimate Health Max than Ultimate Health, see below.</p><p>After two years continuous cover, we cover the cost of a male or female sterilisation as a means of contraception.</p><p>Unlimited up to the Surgical Benefit limit.</p>',
          },
        ],
      },
      {
        type: 'benefit',
        category: 'Loyalty benefit – wellness',
        bothColumns: {
          summaryIcon: 'TickSystemIcon',
          details:
            '<p>Provides you with a reimbursement every three years of continuous cover to help proactively take care of your health through a check up with a GP.</p><p>We pay up to $100 every three years per insured person aged 21 or over.</p>',
        },
      },
      {
        type: 'benefit',
        category: 'ACC treatment injury',
        bothColumns: {
          summaryIcon: 'TickSystemIcon',
          details:
            '<p>Cover for reparative treatment for any injury that  occurs during treatment we’ve paid for that isn’t covered by ACC.</p>',
        },
      },
      {
        type: 'benefit',
        category: 'Delayed Treatment',
        columns: [
          {
            summaryIcon: 'TickSystemIcon',
            details:
              '<p>Covers overseas treatment if you need to wait for treatment in a private hospital in New Zealand for six months or longer.</p>' +
              '<p>We pay up to the Efficient Market Price that would be payable in New Zealand for treatment performed in New Zealand.</p>',
          },
          {
            summaryIcon: 'TickSystemIcon',
            details:
              '<p>Covers overseas treatment if you need to wait for treatment in a private hospital in New Zealand for six months or longer.</p>' +
              '<p>We pay up to the Usual, Customary and Reasonable Charges that would be payable in New Zealand for treatment performed in New Zealand.</p>',
          },
        ],
      },
      {
        type: 'benefit',
        category: 'Medical misadventure',
        bothColumns: {
          summaryIcon: 'TickSystemIcon',
          details:
            '<p>We provide a lump sum payment of $30,000 if an insured person dies due to a medical misadventure.</p>',
        },
      },
      {
        type: 'benefit',
        category: 'Pre-existing cover for newborns',
        bothColumns: {
          summaryIcon: 'TickSystemIcon',
          details:
            '<p>Cover for pre-existing conditions (other than congenital) for dependent children when added within four months of birth.</p>',
        },
      },
      {
        type: 'benefit',
        category: 'non-PHARMAC drugs',
        columns: [
          {
            summaryIcon: 'TickSystemIcon',
            details:
              '<p>Covers up to $20,000 of chemotherapy and immunotherapy medicines that are Medsafe approved but not funded by PHARMAC at the time of your treatment.</p>',
          },
          {
            summaryIcon: 'TickSystemIcon',
            details:
              '<p>Covers the cost of medicines that are Medsafe approved but not funded by PHARMAC at the time of your treatment. This includes all medicines, not just cancer treatment. We pay up to the balance available on your Surgical or Non-Surgical Benefit limit each policy year.</p>',
          },
        ],
      },
      {
        type: 'benefit',
        category: 'Breast symmetry post mastectomy',
        bothColumns: {
          summaryIcon: 'TickSystemIcon',
          details:
            '<p>Following a mastectomy covered by nib, we cover the cost of reconstruction of the affected breast and/or reduction of the unaffected breast to achieve breast symmetry.</p>',
        },
      },
      {
        type: 'benefit',
        category: 'Medical tourism benefit',
        columns: [
          {},
          {
            summaryIcon: 'TickSystemIcon',
            details:
              '<p>If the treatment is available in New Zealand within six months, we cover the costs of treatment in the country of your own choice.</p>' +
              '<p>We pay up to 75% of the Usual, Customary and Reasonable Charges that would be payable in New Zealand for treatment performed in New Zealand.</p>',
          },
        ],
      },
      {
        type: 'benefit',
        category: 'Premium waiver extension',
        columns: [
          {},
          {
            summaryIcon: 'TickSystemIcon',
            details:
              '<p>We cover the cost of all premiums on the policy for up to six months if the policyowner is diagnosed with a terminal illness before age 70.</p>',
          },
        ],
      },
      {
        type: 'benefit',
        category: 'Cancer treatment accessories support',
        columns: [
          {},
          {
            summaryIcon: 'TickSystemIcon',
            details:
              '<p>Cover the cost of a wig, hat, scarf or mastectomy bras during or within six months after cancer surgery or treatment.</p>',
          },
        ],
      },
      {
        type: 'benefit',
        category: 'Cancer treatment counselling and support services',
        columns: [
          {},
          {
            summaryIcon: 'TickSystemIcon',
            details:
              '<p>Covers the cost of counselling and support services that occur within six months after an admission to a private hospital for a cancer surgery or treatment.</p>',
          },
        ],
      },
      {
        type: 'benefit',
        category: 'Cardiac counselling and support services',
        columns: [
          {},
          {
            summaryIcon: 'TickSystemIcon',
            details:
              '<p>Covers the cost of counselling and support services that occur within six months after an admission to a private hospital for a heart surgery.</p>',
          },
        ],
      },
      {
        type: 'benefit',
        category: 'Hospice care',
        columns: [
          {},
          {
            summaryIcon: 'TickSystemIcon',
            details:
              '<p>Provides a payment when you are admitted to a Hospice for three or more consecutive nights.</p><p>We pay up to $300 each night for the third and each subsequent night and up to $3,000 each policy year.</p>',
          },
        ],
      },
      {
        type: 'benefit',
        category: 'Loyalty benefit – bilateral breast reduction',
        columns: [
          {},
          {
            summaryIcon: 'TickSystemIcon',
            details:
              '<p>After 3 years’ continuous cover, we pay up to $10,000 towards the cost of bilateral breast reduction surgery for eligible customers.</p>',
          },
        ],
      },
      {
        type: 'benefit',
        category: 'Loyalty benefit – bariatric surgery',
        columns: [
          {},
          {
            summaryIcon: 'TickSystemIcon',
            details:
              '<p>After 3 years’ continuous cover, we pay up to $10,000 towards the cost of sleeve gastrectomy, gastric banding or gastric bypass surgery for eligible customers.</p>',
          },
        ],
      },
    ],
  },
  {
    type: 'header',
    columns: [
      {
        heading: 'Additional options (Benefit limits are per person, per year):',
      },
      {
        heading: '',
      },
      {
        heading: '',
      },
    ],
  },
  {
    type: 'benefits',
    items: [
      {
        type: 'benefit',
        category: 'Specialist Option',
        bothColumns: {
          summaryIcon: 'TickSystemIcon',
          details:
            '<p>Covers you for specialist consultations and diagnostic procedures that don’t result in hospitalisation.</p><h3>Key features:</h3><ul><li>Unlimited number of visits for specialist consultations, including visits to seek a second opinion</li><li>General diagnostic: up to $3,000 each policy year for costs such as X-rays, arteriogram, ultrasound, scintigrapy, mammography or visual field tests</li><li>Cardiac investigations: up to $60,000 each policy year for costs such as cardiovascular ultrasound, echocardiography and treadmills</li><li>Cover for sports physician treatment up to $500 each policy year</li><li>Cover for pre-existing conditions (other than congenital) for dependent children when added within four months of birth</li></ul>',
        },
      },
      {
        type: 'benefit',
        category: 'Proactive Health Option',
        bothColumns: {
          summaryIcon: 'TickSystemIcon',
          details:
            '<p>This option encourages you to look after your health, whether you want to check any hereditary medical concerns, or just want to stay fit and healthy.</p><p>The premium for this option is one set price, regardless of your age, gender or smoking status. Plus you’ll get up to a maximum of $1,400 worth of cover a year.*</p><ul><li>Health screening: up to $750 each policy year for:<ul><li>Breast screening</li><li>Cervical screening</li><li>Prostate screening</li><li>Bowel screening</li><li>Heart screening</li><li>Mole mapping</li><li>Eye and visual field tests</li><li>Hearing tests</li><li>Bone screening</li></ul></li><li>Allergy testing and vaccinations: up to $100 each policy year</li><li>Dietitian / nutritionist consultations: up to $300 each policy year</li><li>Gym membership, weight loss management programmes and quit smoking programmes: up to $100 each policy year</li><li>Routine health check benefit: $150 for each person after each two years of continuous cover on this option to put towards a medical examination by a GP.</li></ul><p>* Covers 80% of the cost up to the above benefit maximums. A waiting period of six months applies.</p>',
        },
      },
      {
        type: 'benefit',
        category: 'Serious Condition Financial Support Option',
        bothColumns: {
          summaryIcon: 'TickSystemIcon',
          details:
            '<p>This option pays out a one-off lump sum to help reduce the strain of dealing with specific trauma conditions.</p>' +
            '<p>You can use this lump sum for whatever you wish, such as expensive out-of-hospital drug treatments, rehabilitation expenses, paying off the mortgage or maybe a holiday to recuperate.</p>' +
            '<h3>Key features:</h3>' +
            '<ul><li>Choice of cover - $20,000 or $50,000</li>' +
            '<li>Covers 39 trauma conditions, including:<h3>Heart and Circulation</h3>' +
            '<ul><li>Aortic surgery*</li>' +
            '<li>Cardiac arrest – out of hospital*</li>' +
            '<li>Cardiomyopathy*</li>' +
            '<li>Coronary artery angioplasty* (3 vessels or more)</li><li>Coronary artery bypass grafting surgery*</li>' +
            '<li>Heart valve surgery*</li>' +
            '<li>Major heart attack* (myocardial infarction)</li><li>Primary pulmonary hypertension*</li>' +
            '</ul><h3>Organs</h3><ul>' +
            '<li>Chronic liver failure</li>' +
            '<li>Chronic lung failure</li>' +
            '<li>Chronic renal failure</li>' +
            '<li>Major organ transplant*</li>' +
            '<li>Pneumonectomy</li></ul>' +
            '<h3>Functional loss / neurological</h3>' +
            '<ul><li>Advanced dementia (including Alzheimer’s disease)</li>' +
            '<li>Benign tumour of the brain and spinal cord*</li>' +
            '<li>Coma</li>' +
            '<li>Encephalitis</li>' +
            '<li>Loss of independent existence</li>' +
            '<li>Major head trauma</li>' +
            '<li>Motor neurone disease</li>' +
            '<li>Muscular dystrophy</li>' +
            '<li>Multiple sclerosis</li>' +
            '<li>Paralysis<ul>' +
            '<li>Hemiplegia</li>' +
            '<li>Diplegia</li>' +
            '<li>Paraplegia</li>' +
            '<li>Quadriplegia</li>' +
            '<li>Tetraplegia</li>' +
            '</ul></li>' +
            '<li>Stroke*</li>' +
            '<li>Parkinson’s disease</li>' +
            '</ul><h3>Loss of use</h3>' +
            '<ul><li>Deafness</li>' +
            '<li>Loss of limbs and / or sight</li>' +
            '<li>Total and permanent blindness</li>' +
            '<li>Loss of speech</li></ul>' +
            '<h3>Blood disorder</h3>' +
            '<ul><li>Aplastic anaemia</li></ul>' +
            '<h3>Cancer</h3><ul><li>Cancer – life threatening*</li></ul>' +
            '<h3>Other conditions</h3>' +
            '<ul><li>Intensive care</li>' +
            '<li>Severe burns</li>' +
            '<li>Medically acquired HIV</li>' +
            '<li>Occupationally acquired HIV</li>' +
            '</ul></li>' +
            '<li>Free cover for your children under this option, up to half of the sum insured.</li>' +
            '<li>Additional payment for paralysis equal to your sum insured amount.</li></ul>' +
            '<p>* If any of these conditions occur within 90 days of the start date or join date of this option (whichever is applicable), or the date cover is reinstated, no amount is payable.</p>',
        },
      },
      {
        type: 'benefit',
        category: 'GP Option',
        bothColumns: {
          summaryIcon: 'TickSystemIcon',
          details:
            '<p>Perfect for those wanting to cover some of the day-to-day healthcare costs*. This option is particularly useful if you develop a health problem requiring regular GP consultation.</p><h3>Key features:</h3><ul><li><i></i>GP visits: 12 GP visits each policy year; up to $55 for each visit, up to $80 for each home visit, up to $25 for each ACC top-up visit, and up to $200 for each minor surgical procedure</li><li>Prescriptions: up to $15 each prescription, up to $300 each policy year</li><li>Physiotherapy: up to $40 each visit, up to $400 each policy year</li><li>Active Wellness Benefit: $150 for each adult towards the cost of fitness equipment or gym membership, after each two years of continuous cover (provided your total claims under the GP option are less than $150)</li><li>Nurse visits: up to $30 each visit, up to six visits each policy year.</li><li>Cover for pre-existing conditions (other than congenital) for dependent children when added within four months of birth.</li></ul><p>* Covers 100% of the cost up to the above benefit limits. A waiting period of 90 days applies.</p>',
        },
      },
      {
        type: 'benefit',
        category: 'Dental, Optical, and Therapeutic Option',
        bothColumns: {
          summaryIcon: 'TickSystemIcon',
          details:
            '<p>Ideal if you have regular trips to the dentist, optometrist, chiropractor, podiatrist or osteopath, or if you need glasses or contacts from a change in vision*.</p>' +
            '<h3>Key features:</h3>' +
            '<ul><li>Dental treatment: up to $500 each policy year</li>' +
            '<li>Eye care: up to $55 each visit, up to $275 each policy year, and up to $330 each policy year for glasses or contact lenses</li>' +
            '<li>Ear care: up to $250 each policy year for audiology treatments, and up to $250 each policy year for audiometric tests</li>' +
            '<li>Chiropractor: up to $40 each visit, up to $250 each policy year, and up to $80 each policy year for X-rays</li>' +
            '<li>Osteopath: up to $40 each visit, up to $250 each policy year</li>' +
            '<li>Foot care: up to $40 each visit, up to $250 each policy year</li>' +
            '<li>Speech, occupation and eye therapy: up to $40 each visit, up to $300 each policy year</li>' +
            '<li>Acupuncture: up to $40 each visit and up to $250 each policy year</li>' +
            '<li>Cover for pre-existing conditions (other than congenital) for dependent children when added within four months of birth.</li></ul>' +
            '<p>* Covers 80% of the cost up to the above benefit maximums. A waiting period of six months applies.</p>',
        },
      },
      {
        type: 'benefit',
        category: 'non-PHARMAC Plus Option',
        columns: [
          {
            summaryIcon: '',
            details: '',
          },
          {
            summaryIcon: 'TickSystemIcon',
            details:
              '<p>Cover for the cost of many Medsafe-approved medicines that are not funded by PHARMAC, administered in a private hospital and at home.</p><p><b>Key features:</b></p><p>Choice to add $20,000, $50,000, $100,000, $200,000 or $300,000 of cover.</p><p>Cover for:</p><ul><li>The cost of medicines approved for use by Medsafe, prescribed within Medsafe approval, but not funded by PHARMAC at the time of your treatment.</li><li>Non-PHARMAC funded medicines used in a New-Zealand based private hospital, day surgery unit, cancer clinic, or private wing in a public hospital recognised by nib.</li><li>Non-PHARMAC funded medicines used at home for up to six months after you’re hospitalised for treatment - this treatment must be approved by nib and the medicines must relate to it.</li><li>Any medicine administration costs.</li></ul><p>See the option wording for full terms & conditions.</p>',
          },
        ],
      },
    ],
  },
  {
    type: 'footer',
    columns: [
      {
        buttonText: 'Talk to an adviser',
        buttonUrl: '#adviser-contact',
        documents: [
          {
            title: 'Ultimate Health<br/>Policy document',
            url: ultimateHealthPolicy?.file?.url,
          },
          {
            title: 'Ultimate Health / Max<br/>Brochure                    ',
            url: ultimateHealthBrochure?.file?.url,
          },
          {
            title: 'Ultimate Health / Max<br/>Brochure -<br/>Simplified Chinese',
            url: ultimateHealthBrochureSimplifiedChinese?.file?.url,
          },
        ],
      },
      {
        buttonText: 'Talk to an adviser',
        buttonUrl: '#adviser-contact',
        documents: [
          {
            title: 'Ultimate Health Max<br/>Policy document',
            url: ultimateHealthPolicyMax?.file?.url,
          },
          {
            title: 'Ultimate Health / Max<br/>Brochure',
            url: ultimateHealthBrochure?.file?.url,
          },
          {
            title: 'Ultimate Health / Max<br/>Brochure -<br/>Simplified Chinese',
            url: ultimateHealthBrochureSimplifiedChinese?.file?.url,
          },
        ],
      },
    ],
  },
];

export default ultimateHealthBenefits;
