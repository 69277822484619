const easyHealthBenefits = (
  easyHealthPolicyDocument,
  easyHealthBrochure,
  easyHealthBrochureSimplifiedChinese
) => [
  {
    type: 'plans',
    columns: [
      {
        name: 'Easy Health',
        buttonText: 'Talk to an adviser',
        buttonUrl: '#adviser-contact',
      },
    ],
  },
  {
    type: 'header',
    columns: [
      {
        heading: 'Overall benefits (Benefit limits are per person, per year):',
      },
      {
        heading: '',
      },
    ],
  },
  {
    type: 'benefits',
    items: [
      {
        type: 'benefit',
        category: 'Surgical related benefits',
        columns: [
          {
            summary: "<div>Up to:</div><div style='font-size: 28px;'>$300,000</div>",
            details:
              '<p><b>Hospital surgical benefit limit: $300,000</b></p><p>Maximum amount for surgery related benefits.</p>',
          },
        ],
      },
      {
        type: 'benefit',
        category: 'Non-surgical related benefits',
        columns: [
          {
            summary: "<div>Up to:</div><div style='font-size: 28px;'>$200,000</div>",
            details:
              '<p><b>Non-surgical benefit limit: $200,000</b></p><p>Maximum amount for non-surgical related benefits.</p>',
          },
        ],
      },
    ],
  },
  {
    type: 'header',
    columns: [
      {
        heading: 'Benefits (Benefit limits are per person, per year):',
      },
      {
        heading: '',
      },
    ],
  },
  {
    type: 'benefits',
    items: [
      {
        type: 'benefit',
        category: 'Surgery',
        columns: [
          {
            summaryIcon: 'TickSystemIcon',
            details:
              "<p>Surgery in a private hospital.</p><p>Includes costs for:</p><ul><li>Surgeon's operating fees.</li><li>Anaesthetist's fees.</li><li>Intensivist's fees.</li><li>Hospital accommodation (e.g. the admitted patient's bed, a private room, excludes suites).</li><li>Operating theatre fees.</li><li>Surgically implanted prosthesis (in line with the prosthesis schedule).</li><li>Laparoscopic disposables.</li><li>In-hospital X-ray examination and ECG.</li><li>Intensive post-operative care and special in-hospital nursing.</li><li>In-hospital post-operative physiotherapy.</li><li>Ancillary hospital charges (e.g. dressings, sutures, needles, bandages)</li><li>In-hospital pharmaceutical prescriptions that are funded by PHARMAC.</li></ul><p>Click on 'what's covered' for the list of conditions this benefit applies to.</p>",
          },
        ],
      },
      {
        type: 'benefit',
        category: 'Oral surgery',
        columns: [
          {
            summaryIcon: 'TickSystemIcon',
            details:
              '<p>Oral surgery in a private hospital performed by a registered oral or maxillo-facial surgeon.</p><p>We cover the removal of unerupted or impacted teeth by an oral surgeon, dental practitioner, or maxillo-facial surgeon. A 12-month waiting period applies.</p>',
          },
        ],
      },
      {
        type: 'benefit',
        category: 'Cancer treatments',
        columns: [
          {
            summaryIcon: 'TickSystemIcon',
            details:
              '<p>Covers costs for and relating to cancer treatment, including:</p><ul><li>Chemotherapy.</li><li>Radiotherapy.</li><li>Immunotherapy</li><li>Brachytherapy.</li><li>Hospital accommodation (i.e. the admitted patient’s bed and a private room if available).</li><li>In-hospital X-ray examination and ECG.</li><li>Intensive post-operative care and special in-hospital nursing.</li><li>In-hospital post-operative physiotherapy.</li><li>Ancillary hospital charges (e.g. dressings, sutures, needles, bandages).</li><li>In-hospital pharmaceutical prescriptions that are funded by PHARMAC.</li></ul>',
          },
        ],
      },
      {
        type: 'benefit',
        category: 'Follow-up investigations for cancer',
        columns: [
          {
            summaryIcon: 'TickSystemIcon',
            details:
              '<p>Specialist consultation and diagnostic investigation following cancer treatment.</p><p>We cover one specialist consultation, and relevant diagnostic investigations each year. We pay up to $3,000 each year for up to five consecutive years.</p>',
          },
        ],
      },
      {
        type: 'benefit',
        category: 'ACC top up',
        columns: [
          {
            summaryIcon: 'TickSystemIcon',
            details:
              '<p>Covers the difference in cost between what ACC pays for a physical injury and the actual costs of surgery or medical treatment.</p>',
          },
        ],
      },
      {
        type: 'benefit',
        category: 'Hospital specialist consultations',
        columns: [
          {
            summaryIcon: 'TickSystemIcon',
            details:
              '<p>Covers specialist and vocational GP consultations up to six months before and after hospital admission.</p><p>Unlimited consultations during this time, up to your Surgical or Non-Surgical Benefit limit .</p>',
          },
        ],
      },
      {
        type: 'benefit',
        category: 'Hospital diagnostic tests',
        columns: [
          {
            summaryIcon: 'TickSystemIcon',
            details:
              '<p>Cover diagnostic investigations up to six months before and after hospital admission.</p><p>Unlimited diagnostic investigations during this time, up to your Surgical or Non-Surgical Benefit limit.</p>',
          },
        ],
      },
      {
        type: 'benefit',
        category: 'Post-hospital physiotherapy',
        columns: [
          {
            summaryIcon: 'TickSystemIcon',
            details:
              '<p>Covers physiotherapy up to six months after hospitalisation.</p><p>We pay up to $750 per hospitalisation.</p>',
          },
        ],
      },
      {
        type: 'benefit',
        category: 'Post-hospital home care',
        columns: [
          {
            summaryIcon: 'TickSystemIcon',
            details:
              '<p>Covers home care up to six months after hospitalisation.</p><p>We pay up to $150 each day and $6,000 each year.</p>',
          },
        ],
      },
      {
        type: 'benefit',
        category: 'Travel and accommodation',
        columns: [
          {
            summaryIcon: 'TickSystemIcon',
            details:
              '<p>Travel and accommodation costs for you and an accompanying support person when you need to travel further than 100km from where you live for treatment.</p><ul><li>Accommodation: $300 per night.</li><li>Surgery/medical treatment travel: up to $3,000 per year*</li><li>Cancer treatment travel: up to Surgical/Non-Surgical limit*</li></ul>',
          },
        ],
      },
      {
        type: 'benefit',
        category: 'Ambulance transfer',
        columns: [
          {
            summaryIcon: 'TickSystemIcon',
            details:
              '<p>Road ambulance from a public or private hospital to the closest private hospital.</p>',
          },
        ],
      },
      {
        type: 'benefit',
        category: 'Suspension of cover',
        columns: [
          {
            summaryIcon: 'TickSystemIcon',
            details:
              '<p>After 12 months of continuous cover, you can put your cover on hold due to unemployment, redundancy, parental leave or overseas travel or residence.</p>',
          },
        ],
      },
      {
        type: 'benefit',
        category: 'Waiver of premium',
        columns: [
          {
            summaryIcon: 'TickSystemIcon',
            details:
              '<p>We cover the cost of all premiums on the policy for up to two years if a policyowner dies before age 70.</p>',
          },
        ],
      },
      {
        type: 'benefit',
        category: 'Funeral support benefit',
        columns: [
          {
            summaryIcon: 'TickSystemIcon',
            details:
              '<p>We provide a payment of $5,000 if an insured person dies between the age of 16 and 64 (inclusive), from any cause.</p>',
          },
        ],
      },
      {
        type: 'benefit',
        category: 'GP surgery',
        columns: [
          {
            summaryIcon: 'TickSystemIcon',
            details: '<p>Minor surgery performed by a GP.</p><p>We pay up to $1,500 each year.</p>',
          },
        ],
      },
      {
        type: 'benefit',
        category: 'Specialist skin lesion surgery',
        columns: [
          {
            summaryIcon: 'TickSystemIcon',
            details:
              '<p>Skin lesion surgery performed by a specialist.</p><p>We pay up to $6,000 each year.</p>',
          },
        ],
      },
      {
        type: 'benefit',
        category: 'Varicose veins surgery',
        columns: [
          {
            summaryIcon: 'TickSystemIcon',
            details:
              '<p>Varicose vein surgery performed by an appropriate specialist or a Phlebologist who is a Fellow of the Australasian College of Phlebology, in private practice, and holds a current practising certificate.</p>' +
              '<p>No limit on each procedure. Unlimited, up to your Surgical Benefit Limit.</p>',
          },
        ],
      },
      {
        type: 'benefit',
        category: 'Non-surgical hospitalisation',
        columns: [
          {
            summaryIcon: 'TickSystemIcon',
            details:
              '<p>Covers medical treatment (not involving surgery) in a private hospital in relation to a medical condition (e.g. heart disease, asthma, pneumonia or diabetes).</p><p>We also cover the cost of associated intensive nursing care, X-rays, disposables and consumables, dressings and in-hospital pharmaceutical prescriptions that are funded by PHARMAC.</p>',
          },
        ],
      },
      {
        type: 'benefit',
        category: 'Public hospital payment',
        columns: [
          {
            summaryIcon: 'TickSystemIcon',
            details:
              '<p>Provides a payment when you are admitted to a public hospital in New Zealand for three or more consecutive nights.</p><p>We pay up to $300 each night for the third and each subsequent night and up to $3,000 each year.</p>',
          },
        ],
      },
      {
        type: 'benefit',
        category: 'Major diagnostics (not related to hospitalisation)',
        columns: [
          {
            summaryIcon: 'TickSystemIcon',
            details:
              '<p>Covers for the following diagnostic investigations, whether or not you have been hospitalised:</p>' +
              '<ul><li>Arthroscopy</li>' +
              '<li>Capsule endoscopy</li>' +
              '<li>Colonoscopy</li>' +
              '<li>Colposcopy</li>' +
              '<li>CT Scan</li>' +
              '<li>CT Angiogram</li>' +
              '<li>Cystoscopy</li>' +
              '<li>Gastroscopy</li>' +
              '<li>MRI Scan</li>' +
              '<li>Myelogram</li>' +
              '<li>PET Scan</li></ul>' +
              '<p>No limit per diagnostic investigation. Unlimited diagnostic investigations each year, up to your Surgical or Non-Surgical Benefit Limit.</p>',
          },
        ],
      },
      {
        type: 'benefit',
        category: 'Parent accommodation',
        columns: [
          {
            summaryIcon: 'TickSystemIcon',
            details:
              '<p>Accommodation costs for a parent or legal guardian accompanying an insured person under age 20 (inclusive) who is being treated in a private hospital.</p><p>We pay up to $200 each night, and up to $3,000 per hospitalisation.</p>',
          },
        ],
      },
      {
        type: 'benefit',
        category: 'Post-hospital therapeutic care',
        columns: [
          {
            summaryIcon: 'TickSystemIcon',
            details:
              '<p>Includes osteopathic and chiropractic treatment, speech and occupational therapy, sports physician treatment, and dietician consultations up to six months after being discharged from private hospital.</p><p>We pay up to $250 for each hospitalisation, with no limit per treatment / consultation.</p>',
          },
        ],
      },
      {
        type: 'benefit',
        category: 'Overseas treatment',
        columns: [
          {
            summaryIcon: 'TickSystemIcon',
            details:
              '<p>Covers treatment and travel costs when treatment cannot be provided at all in New Zealand and the Ministry of Health has declined funding.</p><p>We cover up to $20,000 each overseas visit.</p>',
          },
        ],
      },
      {
        type: 'benefit',
        category: 'Cover in Australia',
        columns: [
          {
            summaryIcon: 'TickSystemIcon',
            details:
              '<p>Covers cost for treatment undertaken in Australia.</p><p>We pay up to the Efficient Market Price that would be payable in New Zealand for treatment performed in New Zealand.</p>',
          },
        ],
      },
      {
        type: 'benefit',
        category: 'Eye injections',
        columns: [
          {
            summaryIcon: 'TickSystemIcon',
            details:
              '<p>Intravitreal eye injections administered by a specialist.</p><p>We pay up to $3,000 per year.</p>',
          },
        ],
      },
      {
        type: 'benefit',
        category: 'High-Risk pregnancy',
        columns: [
          {
            summaryIcon: 'TickSystemIcon',
            details:
              '<p>Treatment by an obstetrician for assessment and monitoring of recognised risk factors.</p><p>We pay up to $2,000 for each pregnancy.</p>',
          },
        ],
      },
      {
        type: 'benefit',
        category: 'Loyalty benefit – sterilisation',
        columns: [
          {
            summaryIcon: 'TickSystemIcon',
            details:
              '<p>After two years of continuous cover, covers the cost of a male or female sterilisation as a means of contraception.</p><p>We pay up to $1,000 per procedure.</p>',
          },
        ],
      },
      {
        type: 'benefit',
        category: 'Loyalty benefit – wellness',
        columns: [
          {
            summaryIcon: 'TickSystemIcon',
            details:
              '<p>Provides you with a reimbursement every three years of continuous cover to help proactively take care of your health through a check-up with a GP.</p><p>We pay up to $100 every three years per insured person aged 21 or over.</p>',
          },
        ],
      },
      {
        type: 'benefit',
        category: 'Medical misadventure',
        columns: [
          {
            summaryIcon: 'TickSystemIcon',
            details:
              '<p>We provide a lump sum payment of $30,000 if an insured person dies due to a medical misadventure.</p>',
          },
        ],
      },
      {
        type: 'benefit',
        category: 'Non-PHARMAC Cancer Treatment',
        columns: [
          {
            summaryIcon: 'TickSystemIcon',
            details:
              '<p>Covers up to $20,000 of chemotherapy and immunotherapy medicines that are Medsafe approved but not funded by PHARMAC at the time of your treatment.</p>',
          },
        ],
      },
      {
        type: 'benefit',
        category: 'Breast symmetry post mastectomy',
        columns: [
          {
            summaryIcon: 'TickSystemIcon',
            details:
              '<p>Following a mastectomy, we cover the cost of reconstruction of the affected breast and/or reduction of the unaffected breast to achieve breast symmetry.</p>',
          },
        ],
      },
    ],
  },
  {
    type: 'header',
    columns: [
      {
        heading: 'Additional options (Benefit limits are per person, per year):',
      },
      {
        heading: '',
      },
    ],
  },
  {
    type: 'benefits',
    items: [
      {
        type: 'benefit',
        category: 'Serious Condition Lump Sum Option',
        columns: [
          {
            summaryIcon: 'TickSystemIcon',
            details:
              '<p>This option pays out a one-off lump sum to help reduce the strain of dealing with specific trauma conditions.</p>' +
              '<p>You can use this lump sum for whatever you wish, such as expensive out-of-hospital drug treatments, rehabilitation expenses, paying off the mortgage or maybe a holiday to recuperate.</p>' +
              '<h3>Key features:</h3>' +
              '<ul><li>Choice of cover - $20,000 or $50,000</li>' +
              '<li>Covers 17 trauma conditions, including:</li>' +
              '<li><strong>Heart and Circulation</strong><ul>' +
              '<li>Aortic surgery*</li>' +
              '<li>Coronary artery bypass grafting surgery*</li>' +
              '<li>Heart valve surgery*</li>' +
              '<li>Major heart attack* (Myocardial infarction)</li></ul></li>' +
              '<li><strong>Organs</strong><ul>' +
              '<li>Chronic liver failure</li>' +
              '<li>Chronic lung failure</li>' +
              '<li>Chronic renal failure</li>' +
              '<li>Major organ transplant*</li>' +
              '<li>Pneumonectomy</li></ul></li>' +
              '<li><strong>Functional loss / neurological</strong><ul>' +
              '<li>Benign tumour of the brain and spinal cord*</li>' +
              '<li>Paralysis<ul><li>Hemiplegia</li>' +
              '<li>Diplegia</li>' +
              '<li>Paraplegia</li>' +
              '<li>Quadriplegia</li>' +
              '<li>Tetraplegia</li></ul></li>' +
              '<li>Stroke*</li></ul></li>' +
              '<li><strong>Cancer</strong><ul>' +
              '<li>Cancer – life threatening*</li></ul></li></ul>' +
              '<p>* If any of these conditions occur within 90 days of the start date or join date of this option (whichever is applicable), or the date cover is reinstated, no amount is payable.</p>',
          },
        ],
      },
    ],
  },
  {
    type: 'benefits',
    items: [
      {
        type: 'benefit',
        category: 'Proactive Health Option',
        columns: [
          {
            summaryIcon: 'TickSystemIcon',
            details:
              '<p>This option encourages you to look after your health, whether you want to check any hereditary medical concerns, or just want to stay fit and healthy.</p>' +
              '<p>The premium for this option is one set price, regardless of your age, gender or smoking status. ' +
              'Plus you’ll get up to a maximum of $1,400 worth of cover a year.*</p>' +
              '<ul><li>Health screening: up to $750 each policy year for:' +
              '<ul><li>Breast screening</li>' +
              '<li>Cervical screening</li><li>Prostate screening</li>' +
              '<li>Bowel screening</li>' +
              '<li>Mole mapping</li>' +
              '<li>Eye and visual field tests</li>' +
              '<li>Hearing tests</li>' +
              '<li>Heart tests</li>' +
              '<li>Bone screening</li></ul>' +
              '</li><li>Allergy testing and vaccinations: up to $100 each policy year</li>' +
              '<li>Dietician / nutritionist consultations: up to $300 each policy year</li>' +
              '<li>Gym membership, weight loss management programmes and quit smoking programmes: up to $100 each policy year</li>' +
              '<li>Routine health check benefit: $150 for each person after each two years of continuous cover on this option to put towards a medical examination by a GP.</li>' +
              '</ul><p>* Covers 80% of the cost up to the above benefit maximums. A waiting period of six months applies.</p>',
          },
        ],
      },
      {
        type: 'benefit',
        category: 'Non-PHARMAC Plus Option',
        columns: [
          {
            summaryIcon: 'TickSystemIcon',
            details:
              '<p>Cover for the cost of many Medsafe-approved medicines that are not funded by PHARMAC, administered in a private hospital and at home.</p>' +
              '<p><b>Key features:</b></p><p>Choice to add $20,000, $50,000, $100,000, $200,000 or $300,000 of cover.</p>' +
              '<p>Cover for:</p>' +
              '<ul><li>The cost of medicines approved for use by Medsafe, prescribed within Medsafe approval, but not funded by PHARMAC at the time of your treatment.</li>' +
              '<li>Non-PHARMAC funded medicines used in a New-Zealand based private hospital, day surgery unit, cancer clinic, or private wing in a public hospital recognised by nib.</li>' +
              '<li>Non-PHARMAC funded medicines used at home for up to six months after you’re hospitalised for treatment - this treatment must be approved by nib and the medicines must relate to it.</li>' +
              '<li>Any drug administration costs.</li></ul><p>See the option wording for full terms & conditions.</p>',
          },
        ],
      },
    ],
  },
  {
    type: 'footer',
    columns: [
      {
        buttonText: 'Talk to an adviser',
        buttonUrl: '#adviser-contact',
        documents: [
          {
            title: 'Easy Health Policy document',
            url: easyHealthPolicyDocument?.file?.url,
          },
          {
            title: 'Easy Health Brochure',
            url: easyHealthBrochure?.file?.url,
          },
          {
            title: 'Easy Health Brochure - Simplified Chinese',
            url: easyHealthBrochureSimplifiedChinese?.file?.url,
          },
        ],
      },
    ],
  },
];

export default easyHealthBenefits;
